// donwestland-com.scss

header {
  border-radius: 5px;
  margin: 60px 0 80px;
  overflow: hidden;
}

ul {
  line-height: 1.8;
}

.link-list {
  li {
    list-style-type: none;
  }
}

.row {
  display: flex;

  aside {
    display: flex;
    text-align: center;
    margin: auto;
    max-width: 33%;

    img {
      max-width: 400px;
    }

    div {
      display: flex;
      margin: auto;
    }
  }

  section {
    padding: 0 30px;
    width: 67%;
  }
}

.row-spacer {
  border-radius: 2px;
  background: linear-gradient(90deg, hsl(200deg 91% 35%), hsl(200deg 91% 45%));
  height: 4px;
  margin: 60px 0;
}

.video-section-footer {
  display: flex;
  justify-content: center;
}

.video-link-wrapper {
  display: inline-block;
  max-width: 540px;
  margin-top: 20px;
  opacity: 0.8;
  position: relative;

  &:hover {
    opacity: 1;
  }
}

footer {
  padding: 0px 0 60px;
  text-align: center;
}

@media (max-width: 768px) {
  .row {
    flex-direction: column;
    aside {
      margin: auto;
      max-width: inherit;
      width: 100%;
    }
    section {
      width: 100%;
    }
  }
}
